<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16' 
    :style='minHeightStyle'>
    <div
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0'
      :style='`${sidebarBgStyle}`'>
      <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
        <li v-for='(sidebarLink, index) in sidebarLinks'
          :key='`sidebar-link-${sidebarLink.title}-${index}`'
          class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link v-if='sidebarLink.actionType==="open_internal_route"'
            :to='{ name: sidebarLink.link }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </router-link>
          <a v-else-if='sidebarLink.actionType==="open_external_link"'
            :href='sidebarLink.link'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </a>
        </li>
      </ul>
    </div>
    <div class='flex-grow relative px-4 lg:px-0'>
      <div class='py-8 lg:py-16'
        style='padding-bottom: 6rem;'>
        <div class='flex flex-row gap-x-8 mb-4 lg:mb-8'>
          <h1 class='text-3xl font-bold'>{{ abstractFormBoxTitle }}</h1>
          <button
            v-if='myAbstracts.length'
            @click='goToAbstractSubmissions'
            class='py-3 px-8 text-sm uppercase text-center bg-gray-900 text-white rounded-md hover:shadow-md opacity-80 hover:opacity-100'>
            {{ goToAbstractSubmissionsButtonText }}
          </button>
        </div>
        <div v-if='isAbstractSubmissionPeriod' >
          <div v-if='topHtmlContent' v-html='topHtmlContent'></div>
          <edit-form-field
            class='mb-6 w-full lg:w-96' 
            v-for='formField in abstractSubmissionFormFields'
            :key='formField.keyName'
            :edit-form-field='formField'
            :value='fieldValue(formField.category, formField.keyName)'
            @update-edit-form-field-value='updateEditFormFieldValue(formField.category, formField.keyName, ...arguments)' />
          <button
            class='mb-6 w-full lg:w-96 border py-3 text-center text-white text-sm uppercase bg-gray-900 block rounded-md'
            :class='disabledAbstractSubmitButtonClass'
            :disabled='disabledAbstractSubmitButton'
            @click='submitAbstractSubmission'>
            {{ abstractSubmitButtonText }}
          </button>
          <ul class='list-disc list-inside text-red-600 text-xs'>
            <div v-for='formField in  abstractSubmissionFormFields'
              :key='formField.keyName'>
              <li v-if='!fieldValue(formField.category, formField.keyName)'>
                {{ formField.name }} 을/를 입력해주십시오.
              </li>
            </div>
          </ul>
        </div>
        <div v-else>
          {{ abstractSubmissionPeriodOverMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import dayjs from 'dayjs'

export default {
  name: 'AbstractSubmission',
  components: {
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
  },
  watch: {
    'abstractSubmissionDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && this.showingAbstractId) {
          this.getEditingAbstract(this.showingAbstractId)
        } else {
          this.editingAbstractSubmission = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapGetters('events',[
      'showingEventName',
      'eventMainThemeColor',
      'showingEventAbstractSubmissionsConfigurations',
      'eventConfigAbstractSubmissionDeadline',
    ]),
    ...mapFields('abstracts', [
      'editingAbstractSubmission'
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapState('abstracts', [
      'myAbstracts',
    ]),
    showingAbstractId () {
      return parseInt(this.$route.query.abstract_submission_id)
    },
    customBackgroundStyle () {
      return "background-color: #926699; background-image: url(https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/sidebar_image_v2.png); background-repeat: no-repeat; background-position: top; background-size: 100% auto;"
    },
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    isAbstractSubmissionPeriod () {
      return dayjs(this.eventConfigAbstractSubmissionDeadline).isAfter(dayjs(new Date()))
    },
    abstractSubmissionPeriodOverMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionPeriodOverMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionPeriodOverMessage : 'The deadline for abstract submission has passed.'
    },
    abstractCreateFormBoxTitle () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateFormBoxTitle) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateFormBoxTitle : 'Submit Abstract'
    },
    abstractEditFormBoxTitle () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditFormBoxTitle) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditFormBoxTitle : 'Edit Abstract Details'
    },
    abstractFormBoxTitle () {
      return this.showingAbstractId ? this.abstractEditFormBoxTitle : this.abstractCreateFormBoxTitle 
    },
    abstractSubmissionFormFields () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionFormFields) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionFormFields : []
    },
    abstractSubmissionDefault () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDefault) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmissionDefault : {}
    },
    abstractCreateButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateButtonText : 'Submit Abstract'
    },
    abstractEditButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditButtonText : 'Edit Abstract'
    },
    abstractSubmitButtonText () {
      return this.showingAbstractId ? this.abstractEditButtonText : this.abstractCreateButtonText
    },
    abstractSubmitCancelButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractSubmitCancelButtonText) ? this.showingEventAbstractSubmissionsConfigurations.abstractSubmitCancelButtonText : 'Cancel'
    },
    abstractCreateConfirmMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateConfirmMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateConfirmMessage : 'Submit this abstract?'
    },
    abstractEditConfirmMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditConfirmMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditConfirmMessage : 'Edit this abstract?'
    },
    abstractSubmitConfirmMessage () {
      return this.showingAbstractId ? this.abstractEditConfirmMessage : this.abstractCreateConfirmMessage
    },
    abstractCreateDoneMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractCreateDoneMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractCreateDoneMessage : 'Abstract submission done'
    },
    abstractEditDoneMessage () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.abstractEditDoneMessage) ? this.showingEventAbstractSubmissionsConfigurations.abstractEditDoneMessage : 'Abstract edit done'
    },
    abstractSubmitDoneMessage () {
      return this.showingAbstractId ? this.abstractEditDoneMessage : this.abstractCreateDoneMessage
    },
    goToAbstractSubmissionsButtonText () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.goToAbstractSubmissionsButtonText) ? this.showingEventAbstractSubmissionsConfigurations.goToAbstractSubmissionsButtonText : 'My Abstract Submissions'
    },
    topHtmlContent () {
      return (this.showingEventAbstractSubmissionsConfigurations && this.showingEventAbstractSubmissionsConfigurations.topHtmlContent) ? this.showingEventAbstractSubmissionsConfigurations.topHtmlContent : ''
    },
    disabledAbstractSubmitButton () {
      return this.abstractSubmissionFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value)
    },
    disabledAbstractSubmitButtonClass () {
      return this.disabledAbstractSubmitButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    sidebarLinks () {
      return [
        {
          "link": "AbstractRegistrationInformation",
          "title": "초록 접수 안내",
          "actionType": "open_internal_route"
        },
        {
          "link": "AbstractSubmissions",
          "title": "초록 접수",
          "actionType": "open_internal_route"
        },
        {
          "link": "OralPresentationGuidelines",
          "title": "구연 안내",
          "actionType": "open_internal_route"
        },
        {
          "link": "PosterPresentationGuidelines",
          "title": "포스터 안내",
          "actionType": "open_internal_route"
        }
      ]
    },
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    ...mapActions('abstracts', [
      'saveMyAbstract',
      'updateAbstractFile',
      'getEditingAbstract',
    ]),
    submitAbstractSubmission () {
      this.$confirm(this.abstractSubmitConfirmMessage, this.showingEventName, {
        type: 'success',
        confirmButtonText: this.abstractSubmitButtonText,
        cancelButtonText: this.abstractSubmitCancelButtonText
      }).then(() => {
        let data = null
        if (this.editingAbstractSubmission.abstractFileUrl.file, this.editingAbstractSubmission.abstractFileUrl.name){
          data = new FormData()
          data.append('abstract_submission_file', this.editingAbstractSubmission.abstractFileUrl.file, this.editingAbstractSubmission.abstractFileUrl.name)
        }
        this.saveMyAbstract().then((resp) => {
          if (data){
            this.updateAbstractFile({abstractId: resp.id, formData: data}).then(() => {
              this.$alert(this.abstractSubmitDoneMessage, this.showingEventName, {
                type: 'success',
                confirmButtonText: 'OK',
                showClose: false,
              }).then(() => {
                this.$router.push({name: 'AbstractSubmissions'})
              })
            })
          } else {
            this.$alert(this.abstractSubmitDoneMessage, this.showingEventName, {
              type: 'success',
              confirmButtonText: 'OK',
              showClose: false,
            }).then(() => {
              this.$router.push({name: 'AbstractSubmissions'})
            })
          }
        })
      })
    },
    goToAbstractSubmissions () {
      this.$router.push({ name: 'AbstractSubmissions' })
    },
    updateEditFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'abstract_submission') {
        if (arr.length === 2) {
          this.editingAbstractSubmission[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.editingAbstractSubmission[arr[0]] = value
        }
      }
    },                                 
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'abstract_submission') {
        if (arr.length === 2) {
          val = this.editingAbstractSubmission[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.editingAbstractSubmission[arr[0]]
        }
      }
      return val
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'AbstractSubmissions'} })
      }
    })
  },
}
</script>

<style lang='scss' scoped>
.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold  border rounded-md;
}

@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}    
</style>
